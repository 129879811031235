import React, { ReactNode } from "react"
import { Helmet } from "react-helmet"
import * as styles from "./index.module.scss"

import banner from "@images/privacy_policy/banner.png"
import banner_mobile from "@images/privacy_policy/banner_mobile.png"

import { Row, Col } from "antd"
import "antd/dist/antd.css"
function Banner() {
  return (
    <div className={styles.banner_container}>
      {/* <img src={banner} alt="banner" /> */}
      <picture>
        <source media="(min-width: 769px)" srcSet={banner} />
        <source media="(max-width: 768px)" srcSet={banner_mobile} />
        <img src={banner} alt="banner" />
      </picture>
      <h1>隐私政策</h1>
    </div>
  )
}
interface Itext {
  children: ReactNode | ReactNode[] | string
}
function Text({ children }: Itext) {
  return <div className={styles.text}>{children}</div>
}
function Title({ children }: Itext) {
  return <div className={styles.title}>{children}</div>
}
const introMessage =
  "Zero Miracle尊重并保护所有使用Zero Miracle网络服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，Zero Miracle会按照本隐私权政策的规定使用和披露您的个人信息。但Zero Miracle将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，Zero Miracle不会将这些信息对外披露或向第三方提供。Zero Miracle会不时更新本隐私权政策。 您在同意Zero Miracle网络服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于Zero Miracle网络服务使用协议不可分割的一部分。"
const renderMessage = [
  {
    title: "1.适用范围",
    details: [
      "1.在您注册Zero Miracle帐号时，您根据Zero Miracle要求提供的个人注册信息（商家应法律法规要求需公示的企业名称及相关工商注册信息除外）；",
      "2.在您使用Zero Miracle网络服务，或访问Zero Miracle平台网页时，Zero Miracle自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；Zero Miracle也会使用Cookie（统称为“网络追踪器”）收集用户信息。网络跟踪器收集用户上网时的设备和和网络的信息，以及用户访问的日期和时间。这些信息仅供内部使用，如优化服务。并且，这些信息无法追踪到用户个人；",
      "3.Zero Miracle通过合法途径从商业伙伴处取得的用户个人数据。 您了解并同意，以下信息不适用本隐私权政策：",
      "（1）您在使用Zero Miracle平台提供的搜索服务时输入的关键字信息；",
      "（2）Zero Miracle收集到的您在Zero Miracle发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；",
      "（3）违反法律规定或违反Zero Miracle规则行为及Zero Miracle已对您采取的措施。",
    ],
  },
  {
    title: "2.信息使用",
    details: [
      "1.Zero Miracle使用这些由用户提供的信息仅仅出于内部商业用途，例如如优化Zero Miracle的服务，为内外部的商业用途编译综合数据，防范潜在的非法活动，支持用户账户或客户服务，配合任何必要的政府或法律行动，以及其他广告服务相关条款约定的目的；",
      "2.Zero Miracle不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和Zero Miracle（含Zero Miracle关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料；",
      "3.Zero Miracle亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何Zero Miracle平台用户如从事上述活动，一经发现，Zero Miracle有权立即终止与该用户的服务协议；",
      "4.为服务用户的目的，Zero Miracle可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与Zero Miracle合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。",
    ],
  },
  {
    title: "3.信息存储和交换",
    details: [
      "Zero Miracle收集的有关您的信息和资料将保存在Zero Miracle及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或Zero Miracle收集信息和资料所在地的境外并在境外被访问、存储和展示。",
    ],
  },
  {
    title: "4.Cookie的使用",
    details: [
      "当用户访问Zero Miracle的网站时，Zero Miracle可能会使用Cookie或其他技术手段（统称为“网络追踪器”）收集用户的信息。网络追踪器是当用户访问网页时，信息被自动储存的小文本档案。网络追踪器不会用于运行程序或给用户的电脑传送病毒，该技术手段是为了帮用户节省时间而设置的。",
      "1.在您未拒绝接受Cookie的情况下，Zero Miracle会在您的计算机上设定或取用Cookie，以便您能登录或使用依赖于Cookie的Zero Miracle平台服务或功能。Zero Miracle使用Cookie可为您提供更加周到的个性化服务，包括推广服务；",
      "2.您有权选择接受或拒绝接受Cookie。如果您不接受网络追踪器，您可以通过修改浏览器设置的方式删除已设置的网络追踪器并且不接受新的网络追踪器。但如若您选择拒绝接受Cookie，则您可能无法登录或使用依赖于Cookie的Zero Miracle网络服务或功能，比如可能无法存储您的偏好设置，并且某些页面会无法正常显示；",
      "3.通过Zero Miracle所设Cookie所取得的有关信息，将适用本政策。",
    ],
  },
  {
    title: "5.“请勿追踪”信号提示相关说明",
    details: [
      "有些网页会加入“请勿追踪”的功能，可以向用户访问的网站发送信号，指示用户不希望被追踪。因为目前业内还没有对该信号的解释达成共识，所以Zero Miracle的服务目前不会对该信号做出响应。",
    ],
  },
  {
    title: "6.信息的保护",
    details: [
      "Zero Miracle帐号均有安全保护功能，Zero Miracle将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。具体来说，Zero Miracle采用电子、程序及物理等多重安全措施来防止未经授权或非法的信息使用或变更，并防止信息的意外丢失、损毁和破坏。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。",
    ],
  },
  {
    title: "7.信息的保留",
    details: [
      "除非法律或者合同另有要求，只要用户使用Zero Miracle的服务或者根据隐私条款的要求，Zero Miracle会保留从合作的移动渠道商或应用开发者收集到的用户信息。尽管如此，Zero Miracle将以不可识别且汇总的形式去保留和使用这些用户信息，且仅用于必要的公司运营目的，如内部分析、遵守法律责任、解决纠纷、履行协议，并用合适的方式保留或使用用户的信息。除非法律另有规定，信息保存期仅以实现本隐私政策所述目的所需的时间为限。",
    ],
  },
]
export default function Privacypolicy() {
  return (
    <div className={styles.privacy_policy}>
      <Helmet title="隐私政策-Zero Miracle" />
      <Banner />
      <div className={styles.message_container}>
        <div className={styles.intro}>{introMessage}</div>
        {renderMessage.map(item => (
          <div key={item.title}>
            <Title>{item.title}</Title>
            {item.details.map(detail => (
              <Text key={detail}>{detail}</Text>
            ))}
          </div>
        ))}
        <Row style={{ marginTop: "40px" }}>
          {/* <Col md={24} sm={0} xs={0}>
            <Text>
              如对本隐私政策有任何问题，请通过
              <a href="mailto:bd@Zero Miracle.com"> bd@Zero Miracle.com </a>
              联系Zero Miracle。
            </Text>
          </Col> */}

          {/* <Col md={0} sm={24} xs={24}>
                    <Text> 如对本隐私政策有任何问题，<br />
                        请通过<a href="mailto:bd@Zero Miracle.com"> bd@Zero Miracle.com </a>联系Zero Miracle。</Text>
                </Col> */}
        </Row>
      </div>
    </div>
  )
}
